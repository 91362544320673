<template>
  <div class="complaints">

    <h1>How to Complain</h1>

    <p>We aim to offer our members a high quality of service at all times. If you feel dissatisfied with the service
      you have received please tell us about it, and we will do our best to put things right.</p>
    <p>We will provide you with a copy of our internal complaints procedure on request.</p>
    <p>You can make a complaint to us by a number of ways:</p>
    <ul>
      <li>In writing to – Cash Box Credit Union Ltd, Town Hall, Market Street, Denton, M34 2AP.</li>
      <li>Over the phone – <a href="tel:01613423266">0161 342 3266</a></li>
      <li>By Email – <a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a></li>
    </ul>

    <h4>What we will do</h4>
    <p>We aim to resolve complaints as quickly as possible, however, more complex problems may involve conducting
      an investigation. We will review your complaint in a fair, impartial and timely manner.</p>
    <h5>Within 3 working days from the date your complaint was received;</h5>
    <p>If your complaint has not been satisfactorily resolved, you will receive a written letter from us confirming
      that your complaint is being looked into.</p>
    <h5>Within 8 weeks from the date your complaint was received;</h5>
    <p>All of our complaints should be resolved within 8 weeks and you will receive a
      copy of the resolution in writing for your records.</p>
    <p>If it is not possible to resolve within 8 weeks, we will write to you to let you know why we have
      not resolved your issues and when we expect to issue you with a final response.</p>

    <h4>Your rights – Financial Ombudsman Service (FOS)</h4>
    <p>If you are unhappy with the resolution we offer, and have exhausted our complaints procedure, or we have not
      provided a resolution within 8 weeks from the date we received your complaint, you can escalate your complaint
      to FOS. You are able to complain to FOS within 6 months of receiving the credit union’s final response letter.</p>

    <h4>You must have exhausted our complaints procedure in order to take your complaint to FOS</h4>
    <p>FOS is an impartial organisation who mediates on complaints between companies and their
      customers. Their contact details and guidance for complaints are below:</p>

    <h6>Website</h6>
    <p><a href="https://www.financial-ombudsman.org.uk" target="_blank">www.financial-ombudsman.org.uk</a></p>

    <h6>Telephone Number</h6>
    <p><a href="tel:08000234567">0800 023 4567</a></p>

    <h6>E-mail</h6>
    <p><a href="mailto:complaint.info@financial-ombudsman.org.uk">complaint.info@financial-ombudsman.org.uk</a></p>

    <h6>Postal Address</h6>
    <p>
      Financial Ombudsman Service,<br />
      South Quay Plaza,<br />
      183 Marsh Wall,<br />
      London,<br />
      E14 9SR.
    </p>

  </div>
</template>

<script>
export default {
  name: 'Complaints',
  metaInfo() {
    return {
      title: 'Complaints | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>

</style>
